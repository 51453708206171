import React, { useContext } from "react"
import { graphql, Link } from "gatsby"
import * as R from "ramda"

import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImage } from "gatsby-plugin-image"

import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"

import Quote from "../components/page/Quote"

import SearchContext from "../contexts/SearchContext"

import Layout from "../components/layout"
import SEO from "../components/SEO"
import Section from "../components/page/Section"
import Hero from "../components/page/Hero"
import FeaturedPages from "../components/page/FeaturedPages"

import { makeStyles } from "@material-ui/core/styles"

import embeddedImagesPrepareLookup from "../utils/embeddedImagesPrepareLookup"

// const Bold = ({ children }) => <span className="bold">{children}</span>
const useStyles = makeStyles(theme => ({
  heading: {
    marginTop: "1em",
    marginBottom: "0.5em",
    textTransform: "uppercase",
  },
  text: {
    marginBottom: "1.25em",
  },
  embeddedImage: {
    marginTop: "2em",
    marginBottom: "1.25em",
  },
}))

const ArticlePage = ({ data, pageContext, location }) => {
  const classes = useStyles()

  const embeddedImagesFluid = embeddedImagesPrepareLookup(
    R.pathOr([], ["embeddedImages", "edges"], data),
    "contentful_id"
  )

  const linkedArticles = R.path(["page", "linkedArticles"], data)
    ? R.path(["page", "linkedArticles"], data)
    : []
  const linkedIDs =
    linkedArticles.length > 0 ? linkedArticles.map(a => a.id) : []
  const articles = linkedArticles.concat(
    R.path(["articles", "nodes"], data)
      .filter(article => !linkedIDs.includes(article.id))
      .sort(() => 0.5 - Math.random())
      .slice(0, 3 - linkedArticles.length)
  )

  const options = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => (
        <Typography className={classes.heading} variant="h2" component="h2">
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Typography className={classes.heading} variant="h3" component="h3">
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <Typography className={classes.heading} variant="h4" component="h4">
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <Typography className={classes.heading} variant="h5" component="h5">
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <Typography className={classes.heading} variant="h6" component="h6">
          {children}
        </Typography>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography className={classes.text} variant="body1" component="p">
          {children}
        </Typography>
      ),
      [BLOCKS.QUOTE]: (node, children) => <Quote>{children}</Quote>,

      [BLOCKS.EMBEDDED_ASSET]: node => {
        // For the logic behind getting this to work, see answers to
        // https://github.com/contentful/rich-text/issues/70
        const alt = R.path(
          ["data", "target", "fields", "description", "fi"],
          node
        )
        const assetId = R.path(["data", "target", "sys", "contentful_id"], node)
        const fluid = R.path([assetId, "gatsbyImageData"], embeddedImagesFluid)
        return fluid ? (
          <GatsbyImage className={classes.embeddedImage} image={fluid} alt={alt} />
        ) : null
      },
    },
  }

  const searchContext = useContext(SearchContext)
  searchContext.setHeroLoaded(true);

  return (
    <Layout>
      <SEO
        title={R.path(["page", "title"], data)}
        description={R.path(
          ["page", "metaDescription", "metaDescription"],
          data
        )}
        ogTitle={
          R.path(["page", "openGraphTitle"], data) ||
          R.path(["page", "heading"], data) ||
          R.path(["page", "title"], data)
        }
        ogDescription={
          R.path(
            ["page", "openGraphDescription", "openGraphDescription"],
            data
          ) ||
          R.path(["page", "leadText", "leadText"], data) ||
          R.path(["page", "metaDescription", "metaDescription"], data)
        }
        ogImg={
          R.path(["page", "openGraphImage", "file", "url"], data) ||
          R.path(["page", "headingImage", "file", "url"], data)
        }
      />
      <Hero
        backgroundImageFluid={R.path(["page", "headingImage", "gatsbyImageData"], data)}
      />
      <Container>
        <Grid container spacing={3}>
          <Grid item md={2} implementation="css" smDown component={Hidden} />
          <Grid item xs={12} md={8}>
            <Section>
              <Typography variant="h1" component="h1">
                {R.path(["page", "heading"], data) ||
                  R.path(["page", "title"], data)}
              </Typography>
              <br />
              {R.path(["page", "leadText", "leadText"], data) && (
                <Typography variant="subtitle1" component="div">
                  {R.path(["page", "leadText", "leadText"], data)}
                </Typography>
              )}
            </Section>

            <Section>
              <Typography variant="body1" component="div">
                {R.path(["page", "content"], data) && renderRichText(R.path(["page", "content"], data), options)}
              </Typography>
              {R.path(["page", "ctaButtonUrl"], data) &&
                <div style={{textAlign: 'center'}}>
                  <Link
                    to={R.path(["page", "ctaButtonUrl"], data)}
                    style={{textDecoration: 'none'}}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      disableRipple={true}
                      disableFocusRipple={true}
                    >
                      {R.path(["page", "ctaButtonText"], data) || 'Lue lisää'}
                    </Button>
                  </Link>
                </div>
              }
            </Section>
          </Grid>
          <Grid item xs={12}>
            
          </Grid>
        </Grid>
      </Container>
      
      <FeaturedPages title="Sinua voisi kiinnostaa" data={articles} />
    </Layout>
  )
}

export default ArticlePage

export const pageQuery = graphql`
  query articlePageQuery($slug: String, $embeddedImages: [String!]!) {
    page: contentfulArticle(slug: { eq: $slug }) {
      id
      title
      metaDescription {
        metaDescription
      }
      heading
      headingImage {
        title
        description
        file {
          url
          contentType
        }
        gatsbyImageData(
          formats: [AUTO, WEBP],
          layout: CONSTRAINED, 
          width: 1200, 
          quality: 70,
        )
      }
      leadText {
        leadText
      }
      ctaButtonText
      ctaButtonUrl
      publishedDate
      authors {
        name
        role
        company
      }
      content {
        raw
      }
      types {
        type
      }
      openGraphTitle
      openGraphDescription {
        openGraphDescription
      }
      openGraphImage {
        file {
          url
          contentType
        }
      }
      linkedArticles {
        id
        slug
        title
        heading
        leadText {
          leadText
        }
        headingImage {
          file {
            url
            contentType
          }
          gatsbyImageData(
            formats: [AUTO, WEBP],
            layout: CONSTRAINED, 
            width: 400, 
            quality: 90,
          )
        }
      }
    }
    embeddedImages: allContentfulAsset(
      filter: { contentful_id: { in: $embeddedImages } }
    ) {
      edges {
        node {
          contentful_id
          description
          gatsbyImageData(
            formats: [AUTO, WEBP],
            layout: CONSTRAINED, 
            width: 500, 
            quality: 50,
          )
        }
      }
    }
    articles: allContentfulArticle(
      sort: { order: DESC, fields: publishedDate }
      limit: 10
      filter: { publishedDate: { ne: null }, slug: { regex: "/^[^_]/" } }
    ) {
      nodes {
        id
        slug
        title
        heading
        leadText {
          leadText
        }
        headingImage {
          gatsbyImageData(
            formats: [AUTO, WEBP],
            layout: CONSTRAINED, 
            width: 400, 
            quality: 50,
          )
        }
        types {
          type
        }
      }
    }
  }
`
