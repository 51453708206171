import * as R from "ramda"

/*
 This util takes the raw data from GraphQL and turns it into a key:data object 
 for use in the search pages & components
*/
export default (nodes = []) => {
  let images = {}
  R.forEach(node => (images[node.node.contentful_id] = node.node), nodes)
  return images
}
