import React from "react"
import PropTypes from "prop-types"

import Box from "@material-ui/core/Box"
import colors from "../theme/colors"
import typography from "../theme/typography"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    //width: '120%',
  },
  quote: {
    ...typography.quote,
    marginTop: "1em",
    marginBottom: "1em",
    borderLeft: `10px solid`,
    marginLeft: "auto",
    padding: "0.25em 0.5em",

    "@media (min-width: 960px)": {
      width: "120%",
      marginLeft: "-10%",
    },

    "& p": {
      ...typography.quote,
    },
  },
}))

const Quote = ({ children }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <blockquote className={classes.quote}>{children}</blockquote>
    </Box>
  )
}

export default Quote
